<template>
  <xt_vcontainer class="_root1">
    <xt_hcontainer class="_soclial_links" >
      <div>Contact Us</div>
      <!-- <img class="_footer_icon" :src="require('@/assets/imgs/icon/linkedin.svg')"/> -->
      <!-- <img class="_footer_icon" :src="require('@/assets/imgs/icon/github.svg')"/> -->

      <!-- <div class="_footer_icon _footer_icon_rss"></div> -->
      <!-- <xt_circular_btn
        class="_footer_icon"
        :imgSrc="require('@/assets/imgs/icon/rss-box-light.svg')"
        :imgDarkSrc="require('@/assets/imgs/icon/rss-box-dark.svg')"
      ></xt_circular_btn> -->
      <xt_circular_btn
        class="_footer_icon"
        href="https://github.com/gammapi"
        :imgSrc="require('@/assets/imgs/icon/github-light.svg')"
        :imgDarkSrc="require('@/assets/imgs/icon/github-dark.svg')"
      ></xt_circular_btn>
      <xt_circular_btn
        class="_footer_icon"
        href="https://www.linkedin.com/in/steven-jiaxun-tang-2829711a4/"
        :imgSrc="require('@/assets/imgs/icon/linkedin-light.svg')"
        :imgDarkSrc="require('@/assets/imgs/icon/linkedin-dark.svg')"
      ></xt_circular_btn>
      <xt_circular_btn
        class="_footer_icon"
        href="https://utsasrg.github.io"
        :imgSrc="require('@/assets/imgs/icon/umass.png')"
        :imgDarkSrc="require('@/assets/imgs/icon/umass.png')"
      ></xt_circular_btn>

   
    </xt_hcontainer>
    <div class="_footer_bottom_layout">
      <img
        class="_footer_logo"
        :src="require('@/assets/imgs/logo/logo_gray.svg')"
      />
      <xt_hcontainer gutter_col="16px" class="_footer_brand2">
        <xt_rect_btn>Privacy</xt_rect_btn>
        <xt_rect_btn>Terms</xt_rect_btn>
        <xt_rect_btn v-if="!showBeian" href="https://xttechgroup.cn">中国站</xt_rect_btn>
        <xt_rect_btn v-if="showBeian" href="https://xttechgroup.com">Global Site</xt_rect_btn>
        <xt_select>
          <option>English</option>
          <option>中文</option>
        </xt_select>
        <div v-if="showBeian">沪ICP备2020029244号-2</div>
        <div>Ver 0.1.11a</div>
      </xt_hcontainer>
    </div>


  </xt_vcontainer>
</template>

<script setup>
import xt_hcontainer from "@/components/container/xt_hcontainer.vue";
import xt_vcontainer from "@/components/container/xt_vcontainer.vue";
import xt_select from "@/components/select/xt_select.vue";
// import xt_spacer from "@/components/spacer/xt_spacer.vue";
import xt_circular_btn from "@/components/buttons/xt_circular_btn.vue";
import xt_rect_btn from "@/components/buttons/xt_rect_btn.vue";
import { computed } from "vue";

const showBeian=computed(() => {
  return  window.location.hostname.endsWith('.cn') ;
});

</script>


<style scoped>
._root1 {
  align-items: left;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
}

._root1 *{
}

._soclial_links {
  margin-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dadce0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(218, 220, 224);
  align-items: center;
  background-color: inherit;
}

select{
  color: inherit;
}

._footer_brand2 {
  margin-bottom: 25px;
  align-items: baseline;
  text-align: bottom;
}

._footer_icon {
  width: 36px;
  height: 36px;
}

._footer_bottom_layout {
  display: flex;
  flex-wrap: nowrap;
}

._footer_logo {
  margin-right: var(--layout-margin);
  height: 20px;
  fill: #ff000000; /*202124*/
}

._actionBtn {
  width: 24px;
  height: 24px;
}

select {
  border: none;
}

option {
  border: none;
}
</style>
